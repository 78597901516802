import React, { useState } from 'react'

const Context = React.createContext()

export const Provider = props => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [scrollY, setScrollY] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)

  return (
    <Context.Provider
      value={{
        lightboxOpen,
        setLightboxOpen: bool => setLightboxOpen(bool),
        scrollY,
        setScrollY: y => setScrollY(y),
        windowWidth,
        setWindowWidth: w => setWindowWidth(w),
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export default Context
