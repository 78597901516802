exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/ArticlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-careers-page-js": () => import("./../../../src/templates/CareersPage.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-custom-strategy-fund-page-js": () => import("./../../../src/templates/CustomStrategyFundPage.js" /* webpackChunkName: "component---src-templates-custom-strategy-fund-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/NewsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-strategies-page-js": () => import("./../../../src/templates/StrategiesPage.js" /* webpackChunkName: "component---src-templates-strategies-page-js" */),
  "component---src-templates-tcof-page-js": () => import("./../../../src/templates/TCOFPage.js" /* webpackChunkName: "component---src-templates-tcof-page-js" */),
  "component---src-templates-tcof-portfolio-item-page-js": () => import("./../../../src/templates/TCOFPortfolioItemPage.js" /* webpackChunkName: "component---src-templates-tcof-portfolio-item-page-js" */),
  "component---src-templates-team-leader-page-js": () => import("./../../../src/templates/TeamLeaderPage.js" /* webpackChunkName: "component---src-templates-team-leader-page-js" */),
  "component---src-templates-team-member-page-js": () => import("./../../../src/templates/TeamMemberPage.js" /* webpackChunkName: "component---src-templates-team-member-page-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/TeamPage.js" /* webpackChunkName: "component---src-templates-team-page-js" */),
  "component---src-templates-tsef-page-js": () => import("./../../../src/templates/TSEFPage.js" /* webpackChunkName: "component---src-templates-tsef-page-js" */),
  "component---src-templates-tsef-portfolio-item-page-js": () => import("./../../../src/templates/TSEFPortfolioItemPage.js" /* webpackChunkName: "component---src-templates-tsef-portfolio-item-page-js" */)
}

